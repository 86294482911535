import React from "react"
import { NavLink } from "react-router-dom"

function Navigation() {
  return (
    <div className="navigation container" role="navigation">
      <nav className="navbar navbar-expand-sm row">
          <NavLink className="navbar-brand mr-auto" to="/">
          </NavLink>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/Aries" alt="Aries"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Taurus" alt="Taurus"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Gemini" alt="Gemini"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Cancer" alt="Cancer"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Leo" alt="Leo"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Virgo" alt="Virgo"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Libra" alt="Libra"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Scorpio" alt="Scorpio"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Sagittarius" alt="Sagittarius"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Capricorn" alt="Capricorn"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Aquarius" alt="Aquarius"></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Pisces" alt="Pisces"></NavLink>
              </li>
            </ul>

      </nav>
    </div>
  )
}

export default Navigation