import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Footer,
  Home,
  Aries,
  Sagittarius,
  Taurus,
  Gemini,
  Cancer,
  Leo,
  Virgo,
  Libra,
  Scorpio,
  Capricorn,
  Aquarius,
  Pisces,
} from "./components";

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Aries" element={<Aries />} />
      <Route path="/Sagittarius" element={<Sagittarius />} />
      <Route path="/Taurus" element={<Taurus />} />
      <Route path="/Gemini" element={<Gemini />} />
      <Route path="/Cancer" element={<Cancer />} />
      <Route path="/Leo" element={<Leo />} />
      <Route path="/Virgo" element={<Virgo />} />
      <Route path="/Libra" element={<Libra />} />
      <Route path="/Scorpio" element={<Scorpio />} />
      <Route path="/Capricorn" element={<Capricorn />} />
      <Route path="/Aquarius" element={<Aquarius />} />
      <Route path="/Pisces" element={<Pisces />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);



