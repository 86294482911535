import React from 'react'

function Footer() {
  return (
    <div className="footer">
      <footer className="py-5 bg-dark">
          <p className="m-0 text-right fixed-bottom">
            &copy; Moodstills 2022 
          </p>
      </footer>
    </div>
  );
}

export default Footer