import React from 'react'

export default function Buttons(props) {
  return (
    <>
        <div className="buttons">
          {(props.day!=='yesterday') && <a onClick={props.click} name="yesterday">Yesterday</a>}
          {(props.day!=='today') && <a onClick={props.click} name="today">Today</a>}
          {(props.day!=='tomorrow') && <a onClick={props.click}  name="tomorrow">Tomorrow</a>}
        </div>
    </>
  )
}
