import React from 'react'

export default function Card(props) {
  return (
    <>
        <div className="row">
          <div className="col-10 expand">
            <h1 className="font-weight-light">   
                {props.sign.charAt(0).toUpperCase() + props.sign.slice(1)} <span className='range'>({props.range})</span>
            </h1>
          </div>
          <div className="col-2 expand">
             <img src={props.graphic} className={props.sign} alt={props.sign}></img>
          </div>
        </div>

        <h2>{props.date}</h2>
        <p>{props.desc}</p>

        <div className="cards">
          <div className="card">
            <h3>Mood:</h3>
            <p className="desc"> {props.mood}</p>
          </div>

          <div className="card">
            <h3>Color:</h3>
            <p className="desc">{props.color}</p>
          </div> 
         </div> 

         <div className="cards">
         <div className="card">
           <h3>Lucky Time:</h3>
            <p className='desc'>{props.time}</p>
          </div>
          <div className="card">         
            <h3>Lucky Number:</h3>
            <p className="desc">{props.number}</p>
          </div>
          <div className="card">
            <h3>Compatibility:</h3>
            <p className="desc"> {props.compat}</p>
          </div>
        </div>



    </>
  )
}
