import React from 'react'
import '../App.css'
import { NavLink } from 'react-router-dom'

function Home() {
  return (
    <>
    <nav className="menu">
      <input defaultChecked className="menu-toggler" type="checkbox" value="checked" />
        <label htmlFor="menu-toggler"></label>
          <ul>
            <li className="menu-item">
              <NavLink to="/Aries" alt="aries"></NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/Taurus" alt="taurus"></NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/Gemini" alt="gemini"></NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/Cancer" alt="cancer"></NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/Leo" alt="leo"></NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/Virgo" alt="virgo"></NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/Libra" alt="libra"></NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/Scorpio" alt="scorpio"></NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/Sagittarius" alt="Sagittarius"></NavLink>
            </li>
            <li className="menu-item">
            <NavLink to="/Capricorn" alt="Capricorn"></NavLink>
            </li>
            <li className="menu-item">
            <NavLink to="/Aquarius" alt="Aquarius"></NavLink>
            </li>
            <li className="menu-item">
            <NavLink to="/Pisces" alt="Pisces"></NavLink>
            </li>
          </ul>				
    </nav>
    </>
  )
}

export default Home