import React,{useState,useEffect} from 'react'
import { Navigation } from '.'
import { Card } from '.'
import { Buttons } from '.'
import '../App.css'

function Aries() {  
  const [info, setInfo] = useState({
      url: 'https://sameer-kumar-aztro-v1.p.rapidapi.com/?sign=aries&day=',
      sign: 'aries',
      day: 'today',
      graphic: 'aries.png'
    })
  const [data,setData]=useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  function handleClick(event) {      
    const {name} = event.target
      setInfo(prevInfo => ({
        ...prevInfo,
        url: info.url,
        day: name
      }))
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      setHasError(false)
      try {
        const res = await fetch(info.url+info.day, {
          'method': 'POST',
          'headers': {
            'x-rapidapi-host': 'sameer-kumar-aztro-v1.p.rapidapi.com',
            'x-rapidapi-key': '54559effdcmsh4985ab28fbac94dp1f0ca5jsn3acc7061297b'
          }
        })
        const json = await res.json()
        setData(json)
      } catch (error) {
        setHasError(true)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [setData, info.url+info.day])

  return (
    <>
      <Navigation/>
      <div className="container content">
        <div className="align-text-left">
            {hasError && <p>Something went wrong. Please try again later.</p>}
            {isLoading ? (
            <div className="moment">One moment, loading...</div>
            ) : (
              <>
                
                <Card 
                  sign={info && info.sign}
                  range={data && data.date_range}
                  graphic={info && info.graphic}
                  date={data && data.current_date}
                  desc={data && data.description}
                  compat={data && data.compatibility}
                  mood={data && data.mood}
                  color={data && data.color}
                  number={data && data.lucky_number}
                  time={data && data.lucky_time} />

                <Buttons 
                  day={info.day}
                  click={handleClick}
                />
            
              </>
              )}
               
          </div>
        </div>
    </>
  )
}

export default Aries